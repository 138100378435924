import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DataPage from '../DataPage/DataPage';
import Sidebar from '../Sidebar/Sidebar';
import './App.css';

function App() {
    const sidebarConfig = [
        { name: 'Dice', path: '/dice' },
        { name: 'Kura', path: '/kura' }
        // Add more pages and their respective paths
    ];

    return (
        <Router>
            <div className="App">
                <Sidebar config={sidebarConfig} />
                <main className="App-content">
                    <Routes>
                        <Route path="/dice" element={<DataPage title="Dice" apiUrl="https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-9ae66e24-149f-4f04-b28e-4c1f51437fff/api/get_counts" />} />
                        <Route path="/kura" element={<DataPage title="Kura" apiUrl="https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-bb1fe2c0-0eb4-4618-ac96-bb03a8e69c9e/api/get_counts" />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;
