import React from 'react';
import './TopBar.css';

function TopBar({ buttons }) {
    return (
        <div className="TopBar">
            {buttons.map((button, index) => (
                <a key={index} className="TopBarButton">
                    {button}
                </a>
            ))}
        </div>
    );
}

export default TopBar;
