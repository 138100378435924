import { useState, useEffect } from 'react';

export default function useFetchData(url) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(url)
            .then(response => {
                if (response.ok) return response.json();
                throw response;
            })
            .then(data => {
                const sortedData = [...data].sort((a, b) => new Date(a.id) - new Date(b.id));
                const labels = sortedData.map(item => item.id);
                const datasetData = sortedData.map(item => item.totalResults);
                setData({ labels, data: datasetData });
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
                setError(error);
            })
            .finally(() => setLoading(false));
    }, [url]);

    return { data, loading, error };
}
