import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

function Sidebar({ config }) {
    return (
        <aside className="App-sidebar">
            <h1>Nick Mattiacci</h1>
            {config.map((item, index) => (
                <Link key={index} to={item.path}>
                    {item.name}
                </Link>
            ))}
        </aside>
    );
}

export default Sidebar;
