import { useEffect, useRef } from 'react';
import { Chart as ChartJs, CategoryScale, LinearScale, PointElement, LineController, LineElement } from 'chart.js';

ChartJs.register(CategoryScale, LinearScale, PointElement, LineController, LineElement);

function Chart({ data, labels }) {
    const canvasRef = useRef(null);
    const chartRef = useRef(null);

    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d');

        if (chartRef.current) {
            chartRef.current.destroy();
        }

        chartRef.current = new ChartJs(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Total Results',
                        data: data,
                        fill: false,
                        backgroundColor: 'navy',
                        borderColor: 'navy',
                        borderWidth: 2,
                        pointRadius: 2
                    }
                ]
            },
            options: {}
        });

        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [data, labels]);

    return <canvas ref={canvasRef} />;
}

export default Chart;
