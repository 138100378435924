import React from 'react';
import TopBar from '../TopBar/TopBar';
import Chart from '../Chart/Chart';
import useFetchData from '../../hooks/useFetchData';
import './DataPage.css';

function DataPage({ title, apiUrl }) {
    const buttons = ['CSV', 'Run'];
    const { data, loading, error } = useFetchData(apiUrl);

    if (loading) return 'Loading...';
    if (error) return 'Error!';

    return (
        <div className="DataPage">
            <TopBar buttons={buttons} />
            <h1>{title}</h1>
            <Chart {...data} />
        </div>
    );
}

export default DataPage;
